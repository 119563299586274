.App {
  display: grid;
  grid-template-columns: 75% auto;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50px !important;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey !important;
  border-radius: 50px !important;
}

.columnmodal-table .editable-cell-value-wrap {
  cursor: pointer;
}

.column-modal-container {
  top: 50px !important;
}